.App {
  text-align: center;
  font-family: Helvetica;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden { display:none; }

.navbar,
.navbar-default,
.navbar-header {
  /* background-color: goldenrod !important; */
  font-family: 'Helvetica Neue';
  z-index: 100 !important;
}

.bookend {
  padding-top:10px;
  padding-bottom:10px;
  font-family: 'Helvetica Neue';
  /* font-size: 25px; */
  background-color: #DCDCDC;
  /* color:white; */
  border-radius: 5px;
  position: fixed; /* COULD BE THE SOLUTION !!! */
  z-index: 100;
}


.brand {
  color: white;
}

.thick {
  border-top: 3px solid;
}

/* .textinput {

  z-index: 100;
}

.learned {
  text-align: right;
  
  padding-top: 70px;
  position:fixed;
  background: white;
} */

.fixed-top {
  padding-top: 70px;
  text-align: right;
  z-index: -1;
}

.welcome {
  padding-top: 20px;
  font-weight: bold;
  /* background-color:lightgrey; */
}